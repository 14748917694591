<template>
  <div class="column-split-item">
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 64 54"
      xml:space="preserve"
    >
      <g>
        <rect
          x="2"
          y="2"
          width="60"
          rx="5"
          height="50"
          fill="transparent"
          stroke-width="3"
          stroke="#ED5A29"
        />
        <template v-for="index in layout.length - 1">
          <path :key="index" :d="linePath(index)" stroke-width="3" stroke="#ED5A29" />
        </template>
      </g>
    </svg>
  </div>
</template>
<script>
  const SVG_SIZE = 64;
  const COLUMN_COUNT = 12;
  export default {
    props: {
      layout: { type: Array, required: true },
    },
    methods: {
      linePath(index) {
        let sum = 0;
        for (let i = 0; i < index; i++) sum += this.layout[i];
        const lineX = Math.floor((sum / COLUMN_COUNT) * SVG_SIZE);
        return `M${lineX} 52 L ${lineX} 2`;
      },
    },
  };
</script>
